import Vue from 'vue'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets
import '../util/rem'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import locale from 'element-ui/lib/locale/lang/en' // lang ishiban

import '@/styles/index.scss' // global css

import App from './App'
import store from './store'
import router from './router'

import i18n from './ishiban/index';

// import '@/icons' // icon
// import 'lib-flexible'

// import ishiban from '@/language/index'

import echarts from 'echarts'
import "@/assets/text.css";

// import Vue from 'vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'Thp8olSGYLLuUS6s3HbBzGRqVNkUbXKG'
})

Vue.prototype.$echarts = echarts
import VueDPlayer from 'vue-dplayer'
import "vue-dplayer/dist/vue-dplayer.css";
// import 'vue-dplayer/vue-dplayer.css'

Vue.use(VueDPlayer);
// import VueAMap from 'vue-amap'
// Vue.use(VueAMap)
// VueAMap.initAMapApiLoader({
//   // 高德的key
//   key: '145eccb16d44141ff38606341816cb79',
//   // 插件集合
//   plugin: [
//     'AMap.Map',
//     'AMap.MouseTool',
//     'mouseTool.polygon',
//     'AMap.Polygon',
//     'AMap.Autocomplete',
//     'AMap.PlaceSearch',
//     'AMap.Scale',
//     'AMap.OverView',
//     'AMap.ToolBar',
//     'AMap.MapType',
//     'AMap.PolyEditor',
//     'AMap.CircleEditor',
//     'AMap.Geocoder',
//     'AMap.Geolocation'
//   ],
//   // 高德 sdk 版本，默认为 1.4.4
//   v: '1.4.10'
// })

// import Blob from './excel/Blob'
// import Export2Excel from './excel/Export2Excel.js'
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }

// set ElementUI lang to EN
// Vue.use(ElementUI, { locale })
// 如果想要中文版 element-ui，按如下方式声明
// import VueClipboard from 'vue-clipboard2'
//
// Vue.use( VueClipboard )

Vue.use(ElementUI)

import infiniteScroll from 'vue-infinite-scroll'
Vue.use(infiniteScroll)

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  i18n, //将i18n挂载到vue全局使用
  render: h => h(App)
})

