<template>
  <section class="app-main" ref="maxContextBox">
    <transition name="fade-transform" mode="out-in">
      <router-view :key="key" class="maxContextBox"/>
    </transition>
    <!--  底部备案块  -->
    <div class="bootmBox">

    </div>

    <div class="bottom2">

      <div>
        <div class="manageBox">
<!--          <div class="img1" @click="go(1)"><img src="../../assets/img/YQ4.png" alt=""></div>-->
<!--          <div class="img2" @click="go(2)"><img src="../../assets/img/YQ3.png" alt=""></div>-->
<!--          <div class="img3" @click="go(3)"><img src="../../assets/img/YQ2.png" alt=""></div>-->
<!--          <div class="img4" @click="go(4)"><img src="../../assets/img/YQ1.png" alt=""></div>-->
        </div>
        <div class="box">
          <div class="qrBox">
<!--            <div class="qrImg"><img src="../../assets/img/QR.jpg" alt=""></div>-->
<!--            <div class="qrText" v-if="tType == 'CH'">手机扫码体验小程序</div>-->
<!--            <div class="qrText" v-if="tType == 'EN'">Mobile phone scan code experience small program</div>-->
          </div>
          <div class="navList">
            <div class="nItem" v-for="(item,index) in navList">
              <div v-if="!item.hidden">
                <div class="title" v-if="tType == 'CH'">{{ item.meta.title }}</div>
                <div class="title" v-if="tType == 'EN'">{{ item.meta.ENtitle }}</div>
                <div class="li" v-for="(items,indexs) in item.children">
<!--                  <a :href="item.path+'/'+items.path" v-if="!items.hidden">{{ items.meta.title }}</a>-->
                  <a v-show="items.meta.type=='link'"  v-if="!items.hidden && tType == 'CH'" :href="items.path">{{ items.meta.title }}</a>
                  <a v-show="items.meta.type=='link'"  v-if="!items.hidden && tType == 'EN'" :href="items.path">{{ items.meta.ENtitle }}</a>
                  <router-link v-show="items.meta.type!='link'" :to="item.path+'/'+items.path" v-if="!items.hidden && tType == 'CH'">{{ items.meta.title }}</router-link>
                  <router-link v-show="items.meta.type!='link'" :to="item.path+'/'+items.path" v-if="!items.hidden && tType == 'EN'">{{ items.meta.ENtitle }}</router-link>

<!--                  <router-link v-show="items.meta.type=='link'" :to="items.path" v-if="!items.hidden && tType == 'CH'">{{ items.meta.title }}</router-link>-->
<!--                  <router-link v-show="items.meta.type=='link'" :to="items.path" v-if="!items.hidden && tType == 'EN'">{{ items.meta.ENtitle }}</router-link>-->
                </div>
              </div>

              <!--            <div class="li">度假区全景</div>-->
              <!--            <router-link :to=""></router-link>-->
            </div>
          </div>

<!--          <el-backtop target=".app-main" right="210" bottom="200" visibility-height="2800">-->
          <el-backtop target=".app-main" :right="180" :bottom="200">
            <div
                style="{
                text-align: center;
              }"
            >
              <div class="returnTop" >
                <div><span class="el-icon-arrow-up"></span></div>
                <div>TOP</div>
              </div>
            </div>
          </el-backtop>

        </div>

        <div class="div1">
          <div class="listPage">
            <!--          <div class="alert">-->
            <!--            <a href="http://wlt.jiangsu.gov.cn/" target="_blank">江苏省文化和旅游厅</a><div style="display: inline-block;width: 20px;"></div>-->
            <!--            <a href="http://crtt.wuxi.gov.cn/" target="_blank">无锡市文旅局</a><div style="display: inline-block;width: 20px;"></div>-->
            <!--            <a href="http://www.jstour.com/" target="_blank">江苏旅游网</a><div style="display: inline-block;width: 20px;"></div>-->
            <!--            <a href="https://www.mct.gov.cn/" target="_blank">中华人民共和国文化和旅游部</a>-->
            <!--          </div>-->

          </div>
        </div>
        <div class="div2" v-if="tType == 'CH'">
          Copyright © 中国·无锡江南古运河旅游度假区 2022 版权所有　<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">苏ICP备2022017294号-1</a>
        </div>
        <div class="div2" v-if="tType == 'EN'">
          Copyright © China·Wuxi Jiangnan Ancient Canal Tourist Resort 2022 All rights reserved　<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">苏ICP备2022017294号-1</a>
        </div>
      </div>


    </div>

  </section>
</template>

<script>
export default {

  name: 'AppMain',
  data(){
    return {
      tType:'CH',
      isShow:false,
      navList:[]
    }
  },
  computed: {
    key() {
    //   let main=JSON.parse(sessionStorage.getItem("router"))[3]
    //   main=main.path+'/'+main.children[0].path
    //   console.log(main)
    //   return main
      console.log(this.$route.path)
      if(this.$route.path == '/'){
        return 'home'
      }else{
        return this.$route.path
      }

      // console.log(JSON.parse(sessionStorage.getItem("router")))
    }

  },
  watch:{
    $route(to,form) {
      // console.log(to,form)
      // if(to.path == '/hotelHomestay/hotelHomestayTwo'){
      //   this.isShow = false
      // }else{
      //   this.isShow = true
      // }
    },
    // deep: true
  },
  created() {
    // let main=JSON.parse(sessionStorage.getItem("router"))[3]
    // main=main.path+'/'+main.children[0].path
    // console.log(main)
    // console.log(this.$route)
    this.tType = sessionStorage.getItem('textType')
    // console.log(this.$router.options.routes)
    this.$router.options.routes.forEach((item,index)=>{
      if(item.path!='/404' && item.path!='/' && item.path!='*'){
        this.navList.push(item)
      }
    })
    let obj = {
      path: "",
      meta:{
        ENtitle:'blogroll',
        title:'友情链接',
        icon:''
      },
      children:[
        {
          path:'http://crtt.wuxi.gov.cn/',
          name:'http://crtt.wuxi.gov.cn/',
          meta:{
            ENtitle:'Yangzhou Municipal Bureau of culture, radio, television and Tourism',
            title:'无锡市文化广电和旅游局',
            icon:'',
            type:'link'
          },
        },{
          path:'http://www.jstour.com/',
          name:'http://www.jstour.com/',
          meta:{
            ENtitle:'Jiangsu tourism network',
            title:'江苏旅游网',
            icon:'',
            type:'link'
          },
        },{
          path:'http://wlt.jiangsu.gov.cn/',
          name:'http://wlt.jiangsu.gov.cn/',
          meta:{
            ENtitle:'JIANGSU PROVINCIAL DEPARTMENT OF CULTURE AND TOURISM',
            title:'江苏省文化和旅游厅',
            icon:'',
            type:'link'
          },
        },{
          path:'https://mct.gov.cn/whzx/tpxw/',
          name:'https://mct.gov.cn/whzx/tpxw/',
          meta:{
            ENtitle:'MINISTRY OF CULTURE AND TOURISM OF THE PEOPLE\'S REPUBLIC OF CHINA',
            title:'中华人民共和国文化和旅游部',
            icon:'',
            type:'link'
          },
        }
      ]
    }
    this.navList.push(obj)
    console.log(this.navList)

  },
  mounted() {
    // this.$nextTick(()=>{
    //   this.handleScroll()
    //   this.initEvent(this.scrollDom)
    // })
  },
  methods:{
    handleScroll() {
      /* 获取回到顶部的位置元素 .content-container-top */
      // let dom =document.getElementsByClassName('maxContextBox')[0];
      // let dom = this.$refs.maxContextBox;
      this.scrollDom = this.$refs.maxContextBox;
      // this.scrollTop = dom.scrollTop;
      // console.log(dom.scrollTop)

    },
    initEvent(dom) {
      dom.addEventListener('scroll', this.scrollHander);
    },
    scrollHander() {
      const top = this.scrollDom.scrollTop;
    },
    go(i){
      console.log(i)
      if(i==1){
        window.location.href='http://wlt.jiangsu.gov.cn'
      }else if(i==2){
        window.location.href='http://wglj.yangzhou.gov.cn'
      }else if(i==3){
        window.location.href='http://www.jstour.com'
      }else if(i==4){
        window.location.href='https://www.mct.gov.cn'
      }

    }
  },
}
</script>

<style scoped>
.maxContextBox{
  background-image:url("http://admin.wxjngyh.com/profile/upload/2022/05/26/%E5%A4%A7%E8%83%8C%E6%99%AF_20220526112154A017.png") ;
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
}
.app-main {
  /*50 = navbar  */
  max-height: calc(100vh - 120px);    /* 减顶部的130px和底部的250px */
  /*width: 100%;*/
  position: relative;
  /*overflow: auto;*/
  overflow-y: scroll;
  overflow-x: hidden;
  background: #f5f5f5;
}
.app-main::-webkit-scrollbar {
  width: 6px;
  /*height: 4px;*/
}
.app-main::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  background: rgba(0,0,0,0.2);
}
.app-main::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  border-radius: 0;
  background: rgba(0,0,0,0.1);

}
.fixed-header+.app-main {
  padding-top: 50px;
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>

<style scoped lang="less">
  .filingInfo{
    height: 250px;
    overflow: hidden;
    //background: red;
    .bottomLogo{
      width: 170px;
      height: 75px;
      margin: 20px auto;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .listPage{
      display: flex;
      justify-content: center;
      //align-items: center;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 44px;
      span{
        margin: 0 15px;
      }
    }
    .newsPath{
      display: flex;
      justify-content: center;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
      margin-bottom: 7px;
      span:nth-child(1){
        margin-right: 20px;
      }
    }
    .filing{
      text-align: center;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
    }
  }
  .bottom2{
    //width: calc(100% + 300px);
    width: 100%;
    background: #007B8A;
    overflow: hidden;
    .manageBox{
      width: 1300px;
      //height: 140px;
      //height: 20px;
      //background: #FFFFFF;
      //box-shadow: 0px -2px 15px 0px rgba(225, 225, 225, 0.5);
      border-radius: 5px;
      margin: 50px auto;
      //transform: translate(0, -50%);
      display: flex;
      align-items: center;
      //padding: 41px 0;
      overflow: hidden;
      .img1,.img2,.img3{
        margin-left: 50px;
        img{
          width: 100%;
          display: block;
          margin: 12px 0 12px 0;
        }
      }
      .img3{
        margin-left: 50px;
        img{
          width: 100%;
          display: block;
          margin: 9px 0 6px 0;
        }
      }
      .img4{
        //width: 100%;
        margin-left: 50px;
        img{
          width: 100%;
          display: block;
        }
      }
    }
    .box{
      display: flex;
      //justify-content: space-between;
      padding-left: 245px;
      padding-right: 200px;
      padding-bottom: 60px;
      .qrBox{
        width: 230px;
        height: 280px;
        //background: #FFFFFF22;
        border-radius: 20px;
        .qrImg{
          width: 178px;
          height: 178px;
          background: #FFFFFF;
          border-radius: 50%;
          margin: 26px auto 28px;
          overflow: hidden;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .qrText{
          font-size: 14px;
          font-family: STSongti-SC-Regular, STSongti-SC;
          font-weight: 400;
          color: #FFFFFF;
          text-align: center;
        }
      }

      .navList{
        //margin-left:130px;  // 注释
        display: flex;
        justify-content: space-between;
        .nItem{
          margin-left: 40px;  // 注释
          .title{
            font-size: 20px;
            font-family: STSongti-SC-Black, STSongti-SC;
            font-weight: 900;
            color: #FFFFFF;
            margin-bottom: 24px;
          }
          .li{
            font-size: 14px;
            font-family: STSongti-SC-Regular, STSongti-SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 46px;
          }
          .li:hover{
            color: rgba(255,255,255,0.6);
          }
        }
      }

      .returnTop{
        width: 80px;
        height: 80px;
        background: #FFFFFF33;
        border-radius: 50%;
        overflow: hidden;
        padding: 16px 0 0 0;
        //margin-top: 55px;
        &:hover{
          background: #ffffff;
          div{
            color: #337D8D;
          }
        }
        div{
          text-align: center;
          color: #ffffff;
        }
      }
    }
  }
  .div2{
    border-top: 1px solid #FFFFFF22;
    font-size: 14px;
    font-family: STSongti-SC-Regular, STSongti-SC;
    font-weight: 400;
    color: #FFFFFF;
    padding: 12px 0 17px;
    text-align: center;
  }
  .alert a,.div2 a{
    &:hover{
      color: #20a0ff;
    }
  }
  /deep/.el-backtop{
    background: none;
    width: auto;
    height: auto;
  }
  .el-menu--horizontal{
    border-radius: 20px;
    overflow: hidden;
    /deep/.el-menu--popup{
      border-radius: 20px;
    }
  }
</style>
