import Vue from 'vue'
import VueI18n from 'vue-i18n'

import enLocale from './en'
import zhLocale from './zh'

Vue.use(VueI18n)

const i18nList = {
    locale:'en',
    messages: {
        'zh':require('./zh'),
        'en':require('./en')
        // zh:{
        //     ...zhLocale
        // },
        // en:{
        //     ...enLocale
        // }
    }
}

const i18n = new VueI18n(i18nList)

export default i18n;
