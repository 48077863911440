// import Vue from 'vue'
// import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
//
// Vue.use(VueRouter)
//
// const routes = [
//   {
//     path: '/',
//     name: 'Home',
//     component: Home
//   },
//   {
//     path: '/about',
//     name: 'About',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
//   }
// ]
//
// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })
//
// export default router


import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

/* Layout */
import Layout from '@/layout'
export const constantRoutes = [
  // {
  //   path: '/',
  //   component: () => import('@/views/login/index'),
  //   hidden: true
  // },

  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    meta: { title: '首页', ENtitle:'Home', icon: '' },
    children: [
      {
        path: '/',
        name: '/',
        // component: () => import('@/views/home/index'),
        component: () => import('@/views/login/indexS.vue'),
        meta: { title: '首页', ENtitle:'Home', icon: '' }
      },{
        path: 'car',
        name: 'car',
        // component: () => import('@/views/home/index'),
        component: () => import('@/views/login/car.vue'),
        meta: { title: '停车场', ENtitle:'Parking lot', icon: '' },
        hidden: true
      },{
        path: 'featureConsumption',
        name: 'featureConsumption',
        // component: () => import('@/views/home/index'),
        component: () => import('@/views/featureConsumption/featureConsumption.vue'),
        meta: { title: '特色消费', ENtitle:'feature Consumption', icon: '' },
        hidden: true
      },{
        path: 'featureConsumptionInfo',
        name: 'featureConsumptionInfo',
        // component: () => import('@/views/home/index'),
        component: () => import('@/views/featureConsumption/featureConsumptionInfo.vue'),
        meta: { title: '特色消费', ENtitle:'feature ConsumptionInfo', icon: '' },
        hidden: true
      }
    ]
  },
  {
    path: '/searchPage',
    component: Layout,
    meta: { title: '搜索页面', ENtitle:'Search page', icon: '' },
    hidden: true,
    children: [
      {
        path: 'searchPage',
        name: 'searchPage',
        // component: () => import('@/views/home/index'),
        component: () => import('@/views/searchPage/searchPage.vue'),
        meta: { title: '搜索列表', ENtitle:'Search page', icon: '' },
        hidden: true
      },
      {
        path: 'searchInfo',
        name: 'searchInfo',
        // component: () => import('@/views/home/index'),
        component: () => import('@/views/searchPage/searchInfo.vue'),
        meta: { title: '搜索', ENtitle:'SearchInfo', icon: '' },
        hidden: true
      },
    ]
  },
  {
    path: '/vacation',
    component: Layout,
    meta: { title: '度假区导览', ENtitle:'Overview', icon: '' },
    children: [
      {
        path: 'vacation',
        name: 'vacation',
        // component: () => import('@/views/home/index'),
        component: () => import('@/views/vacation'),
        meta: { title: '度假区概况', ENtitle:'Introduction', icon: '' }
      },
      {
        path: 'vacation?type=1',
        name: 'vacation',
        component: () => import('@/views/vacation/lantu'),
        meta: { title: '度假区全景', ENtitle:'Panoramic', icon: '' }
      },
      // {
      //   path: 'vacation?type=3',
      //   name: 'vacation',
      //   component: () => import('@/views/vacation/virtual'),
      //   meta: { title: '虚拟游览', ENtitle:'visit', icon: '' }
      // },
      {
        path: 'vacation?type=2',
        name: 'vacation',
        component: () => import('@/views/vacation/jiaotong'),
        meta: { title: '交通指引', ENtitle:'Transportation', icon: '' }
      },
    ]
  },
  {
    path: '/history',
    component: Layout,
    meta: { title: '度假资源', ENtitle:'Vacation resources', icon: '' },
    children: [
      {
        path: 'history',
        name: 'history',
        // component: () => import('@/views/lishi'),   // 为合并的页面
        component: () => import('@/views/lishi/pageHeBing'),   //    合并页面
        // meta: { title: '江南运河风情', ENtitle:'tuttiKiwi', icon: '' }
        meta: { title: '度假资源', ENtitle:'Jiangnan Canal style', icon: '' }
      },
      {
        path: 'historyInfo',
        name: 'historyInfo',
        component: () => import('@/views/lishi/indexInfo'),
        meta: { title: '江南运河风情', ENtitle:'TuttiKiwiInfo', icon: '' },
        hidden: true
      },
      {
        path: 'nationality',
        name: 'nationality',
        component: () => import('@/views/lishi/nationality'),
        meta: { title: '民族工商文化', ENtitle:'National industrial and commercial culture', icon: '' },
        hidden: true
      },
      {
        path: 'nationalityInfo',
        name: 'nationalityInfo',
        component: () => import('@/views/lishi/nationalityInfo'),
        meta: { title: '民族工商文化', ENtitle:'CultureInfo', icon: '' },
        hidden: true
      },
    ]
  },
  {
    path: '/information',
    component: Layout,
    meta: { title: '动态资讯', ENtitle:'News', icon: '' },
    children: [
      {
        path: 'announcement',
        name: 'announcement',
        // component: () => import('@/views/information/gonggao'),
        component: () => import('@/views/information/pageHeBing'),
        // meta: { title: '最新公告', ENtitle:'Announcement', icon: '' }
        meta: { title: '动态资讯', ENtitle:'News', icon: '' }
      },
      {
        path: 'information',
        name: 'Information',
        component: () => import('@/views/information/youhui'),
        meta: { title: '游览资讯', ENtitle:'Infomation', icon: '' },
        hidden: true
      },
      // {
      //   path: 'information?type=2',
      //   name: 'Information',
      //   component: () => import('@/views/information/jieqing'),
      //   meta: { title: '节庆活动', icon: '' }
      // },
      {
        path: 'informationinfo',
        name: 'Informationinfo',
        component: () => import('@/views/information/informationInfo'),
        meta: { title: '动态资讯', ENtitle:'InformationInfo', icon: '' },
        hidden: true
      }
    ]
  },
  // {
  //   path: '/travelStrategy',
  //   component: Layout,
  //   meta: { title: '旅游攻略', icon: '' },
  //   children: [
  //     {
  //       path: 'travelStrategy',
  //       name: 'TravelStrategy',
  //       component: () => import('@/views/travelStrategy/travelStrategy'),
  //       meta: { title: '旅游专线', icon: '' }
  //     },
  //     {
  //       path: 'travelStrategyGL',
  //       name: 'travelStrategyGL',
  //       component: () => import('@/views/travelStrategy/travelStrategyGL'),
  //       meta: { title: '游玩攻略', icon: '' },
  //     },
  //     // {
  //     //   path: 'travelStrategyTwo',
  //     //   name: 'TravelStrategyTwo',
  //     //   component: () => import('@/views/travelStrategy/travelStrategyTwo'),
  //     //   meta: { title: '游玩游记', icon: '' },
  //     // },
  //     {
  //       path: 'travelStrategyTJ',
  //       name: 'travelStrategyTJ',
  //       component: () => import('@/views/travelStrategy/travelStrategyTJ'),
  //       meta: { title: '景点推荐', icon: '' },
  //     },
  //     // {
  //     //   path: 'travelStrategyThree',
  //     //   name: 'TravelStrategyThree',
  //     //   component: () => import('@/views/travelStrategy/travelStrategyThree'),
  //     //   meta: { title: '全景图', icon: '' },
  //     //   // hidden: true
  //     // },
  //     {
  //       path: 'travelStrategyInfo',
  //       name: 'TravelStrategyInfo',
  //       component: () => import('@/views/travelStrategy/travelStrategyInfo'),
  //       meta: { title: '旅游攻略', icon: '' },
  //       hidden: true
  //     },
  //     {
  //       path: 'travelStrategyTwoInfo',
  //       name: 'TravelStrategyTwoInfo',
  //       component: () => import('@/views/travelStrategy/travelStrategyTwoInfo'),
  //       meta: { title: '旅游攻略游记攻略', icon: '' },
  //       hidden: true
  //     }
  //   ]
  // },
  {
    path: '/playturn',
    component: Layout,
    meta: { title: '玩转度假区', ENtitle:'Experiences With canal', icon: '' },
    children: [
      {
        path: 'haveScenery',
        name: 'haveScenery',
        component: () => import('@/views/playturn/haveScenery'),
        meta: { title: '运河有景', ENtitle:'Scenery', icon: '' },
        // hidden: true
      },
      {
        path: 'haveSceneryInfo2',
        name: 'haveSceneryInfo2',
        // component: () => import('@/views/home/index'), 详情
        component: () => import('@/views/playturn/components/haveSceneryInfo2'),
        meta: { title: '运河有宿', ENtitle:'HaveNightInfo', icon: '' },
        hidden: true
      },
      {
        path: 'haveSceneryInfo',
        name: 'haveSceneryInfo',
        // component: () => import('@/views/home/index'),  合并（有味，有趣，有礼）
        component: () => import('@/views/playturn/components/haveSceneryInfo'),
        meta: { title: '运河有景', ENtitle:'HaveViewInfo', icon: '' },
        hidden: true
      },{
        path: 'haveNight',
        name: 'haveNight',
        component: () => import('@/views/playturn/haveNight'),
        meta: { title: '运河有宿', ENtitle:'Rooms & Suites', icon: '' },
        // hidden: true
      },
      {
        path: 'haveNightInfo2',
        name: 'HaveNightInfo2',
        // component: () => import('@/views/home/index'), 详情
        component: () => import('@/views/playturn/components/haveNightInfo2'),
        meta: { title: '运河有宿', ENtitle:'HaveNightInfo', icon: '' },
        hidden: true
      },{
        path: 'haveNightInfoNew',
        name: 'haveNightInfoNew',
        // component: () => import('@/views/home/index'), 详情
        component: () => import('@/views/playturn/components/haveNightInfoNew'),
        meta: { title: '运河有宿', ENtitle:'HaveNightInfo', icon: '' },
        hidden: true
      },
      {
        path: 'Tasty',
        name: 'Tasty',
        component: () => import('@/views/playturn/Tasty'),
        meta: { title: '运河有味', ENtitle:'Dining', icon: '' },
        // hidden: true
      },{
        path: 'TastyInfo',
        name: 'TastyInfo',
        // component: () => import('@/views/home/index'),
        component: () => import('@/views/playturn/components/TastyInfo'),
        meta: { title: '运河有味', ENtitle:'HaveSaporInfo', icon: '' },
        hidden: true
      },
      {
        path: 'Polite',
        name: 'Polite',
        component: () => import('@/views/playturn/Polite'),
        meta: { title: '运河有礼', ENtitle:'Shops', icon: '' },
        // hidden: true
      },{
        path: 'PoliteInfo',
        name: 'PoliteInfo',
        // component: () => import('@/views/home/index'),
        component: () => import('@/views/playturn/components/PoliteInfo'),
        meta: { title: '运河有礼', ENtitle:'DecorousnessInfo', icon: '' },
        hidden: true
      },
      {
        path: 'Interesting',
        name: 'Interesting',
        component: () => import('@/views/playturn/Interesting'),
        meta: { title: '运河有趣', ENtitle:'Activities', icon: '' },
        // hidden: true
      },
      {
        path: 'InterestingInfo',
        name: 'InterestingInfo',
        component: () => import('@/views/playturn/components/InterestingInfo'),
        meta: { title: '运河有趣', ENtitle:'AmusingInfo', icon: '' },
        hidden: true
      },
      // {
      //   path: 'playturnarea',
      //   name: 'playturnarea',
      //   // component: () => import('@/views/home/index'),
      //   component: () => import('@/views/playturn/playturnarea'),
      //   meta: { title: '玩转度假区', icon: '' },
      //   hidden: true
      // },
      // {
      //   path: 'playtyrndetails',
      //   name: 'playtyrndetails',
      //   component: () => import('@/views/playturn/playtyrndetails'),
      //   meta: { title: '玩转度假区', icon: '' },
      //   hidden: true
      // }
    ]
  },
  // {
  //   path: '/hotelHomestay',
  //   component: Layout,
  //   // meta: { title: '酒店民宿', icon: '' },
  //   children: [
  //     {
  //       path: 'hotelHomestay',
  //       name: 'HotelHomestay',
  //       component: () => import('@/views/hotelHomestay/hotelHomestay.vue'),
  //       meta: { title: '酒店民宿', icon: '' }
  //     },
  //     {
  //       path: 'hotelHomestayTwo',
  //       name: 'HotelHomestayTwo',
  //       component: () => import('@/views/hotelHomestay/hotelHomestayTwo'),
  //       meta: { title: '酒店民宿', icon: '' },
  //       hidden: true
  //     },
  //     {
  //       path: 'hotelHomestayThree',
  //       name: 'HotelHomestayThree',
  //       component: () => import('@/views/hotelHomestay/hotelHomestayThree'),
  //       meta: { title: '酒店民宿', icon: '' },
  //       hidden: true
  //     },
  //     {
  //       path: 'hotelHomestayFour',
  //       name: 'HotelHomestayFour',
  //       component: () => import('@/views/hotelHomestay/hotelHomestayFour'),
  //       meta: { title: '酒店民宿预订支付', icon: '' },
  //       hidden: true
  //     }
  //   ]
  // },
  {
    path: '/ticketing',
    component: Layout,
    meta: { title: '线上预约', ENtitle:'Appointment', icon: '' },
    children: [
      // {
      //   path: 'ticketingabout',
      //   name: 'ticketingabout',
      //   component: () => import('@/views/ticketing/ticketingabout'),
      //   meta: { title: '景区门票', icon: '' }
      // },
      // {
      //   path: 'ticketingabout?type=1',
      //   name: 'ticketingabout',
      //   component: () => import('@/views/ticketing/ticketingabout'),
      //   meta: { title: '组合门票', icon: '' }
      // },
      // {
      //   path: 'hotelHomestay',
      //   name: 'HotelHomestay',
      //   component: () => import('@/views/hotelHomestay/hotelHomestay.vue'),
      //   meta: { title: '酒店民宿', ENtitle:'hotel', icon: '' }
      // },
      {
        path: 'hotelHomestayInfo',
        name: 'hotelHomestayInfo',
        component: () => import('@/views/hotelHomestay/components/hotelHomestayInfo.vue'),
        meta: { title: '酒店民宿', ENtitle:'HotelInfo', icon: '' },
        hidden: true
      },
      {
        path: 'ticketingabout',
        name: 'ticketingabout',
        component: () => import('@/views/ticketing/ticketingabout'),
        meta: { title: '特色游船', ENtitle:'Tourist boat', icon: '' }
      },
      // {
      //   path: 'ticketinganticipate',
      //   name: 'ticketinganticipate',
      //   component: () => import('@/views/ticketing/ticketinganticipate'),
      //   meta: { title: '票务预约', icon: '' },
      //   hidden: true
      // },
      // {
      //   path: 'ticketingpayment',
      //   name: 'ticketingpayment',
      //   component: () => import('@/views/ticketing/ticketingpayment'),
      //   meta: { title: '票务预约', icon: '' },
      //   hidden: true
      // },
    ]
  },/**/
  {
    path: '/we',
    component: Layout,
    meta: { title: '关于我们', ENtitle:'About us', icon: '' },
    children: [
      {
        path: 'index',
        name: 'we',
        component: () => import('@/views/We/index'),
        meta: { title: '度假区大事记', ENtitle:'Memorabilia', icon: '' }
      },
      {
        path: 'indexInfo',
        name: 'indexInfo',
        component: () => import('@/views/We/indexsInfo'),
        meta: { title: '度假区大事记', ENtitle:'Chronicle events Info', icon: '' },
        hidden: true
      },
      /*{
        path: 'index?type=1',
        name: 'we',
        component: () => import('@/views/We/index'),
        meta: { title: '所获荣誉', ENtitle:'honor', icon: '' }
      },*/
      {
        path: 'honorInfo',
        name: 'honorInfo',
        component: () => import('@/views/We/honorInfo'),
        meta: { title: '所获荣誉', ENtitle:'HonorInfo', icon: '' },
        hidden: true
      },
      {
        path: 'index?type=2',
        name: 'we',
        component: () => import('@/views/We/index'),
        meta: { title: '联系我们', ENtitle:'Contact us', icon: '' }
      },
    ]
  },
  // {
  //   path: '/setUp',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'setUp',
  //       name: 'Setup',
  //       component: () => import('@/views/setup/setup'),
  //       meta: { title: '基本设置', icon: 'el-icon-s-tools' }
  //     }
  //   ]
  // },
  /*{
    path: '/accountManagement',
    component: Layout,
    children: [
      {
        path: 'accountManagement',
        name: 'accountManagement',
        component: () => import('@/views/accountManagement/accountManagement'),
        meta: { title: '账户管理', icon: 'el-icon-user-solid' }
      },{
        path: '/addAccount',
        component: () => import('@/views/accountManagement/addAccount'),
        meta: { title: '账户管理'},
        hidden: true
      },
    ]
  },*/

  { path: '*', redirect: '/404', hidden: true }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router

