const zh ={
    commons: {
        admin: 'Admin',
        editor: 'Editor',
        quit: 'Sign Out',
        hi: 'Hi',
        fundManage: 'Money',
        fundList: 'MoneyList',
        chinaTabsList: 'AreaList',
        fundData: 'FundData',
        fundPosition: 'FundPosition',
        typePosition: 'TypePosition',
        incomePayPosition: 'IncomePayPosition',
        permission: 'Permission',
        pagePer: 'PagePermission',
        directivePer: 'DirectivePermission',
        errorPage: 'ErrorPage',
        page401:'401',
        page404:'404',
        wechatNumber: 'wechat'
    }
}
export default zh;
