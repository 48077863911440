const zh = {
    // layout
    commons: {
        admin: '管理员',
        editor: '瑞吉',
        quit: '退出',
        hi: '您好',
        index: '首页',
        userManage: '用户管理',
        share: '分享功能',
        infoManage: '信息管理',
        fundManage: '资金管理',
        fundList: '资金流水',
        chinaTabsList: '区域投资',
        fundData: '资金数据',
        fundPosition: '投资分布',
        typePosition: '项目分布',
        incomePayPosition: '收支分布',
        permission: '权限设置',
        pagePer: '页面权限',
        directivePer: '按钮权限',
        errorPage: '错误页面',
        page401:'401',
        page404:'404',
        wechatNumber: '微信号'
    }
}

export default zh;
