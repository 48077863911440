<template>
<!--  <div>-->
    <div class="userInfo">
      <div class="info">
        <div class="block">
          <el-avatar shape="square" :size="80" :src="squareUrl"></el-avatar>
        </div>
        <div>admin</div>
        <div>退出</div>
      </div>
      <div class="auditInfo">
        <div class="audit_header">
          <div class="num">65489</div>
          <div class="text">待审核信息</div>
        </div>
        <div class="auditList" style="height: calc(100vh - 300px);overflow-y: scroll">
          <div class="item" v-for="(item,index) in 20">
            <div class="block">
              <el-avatar shape="square" :size="50" :src="squareUrl"></el-avatar>
            </div>
            <div class="name">姓名信息</div>
            <div class="audit">审核></div>
          </div>
          <div class="more">查看更多</div>
        </div>
      </div>
<!--      <div class="auditInfo">-->
<!--        <div class="audit_header">-->
<!--          <div class="num">65489</div>-->
<!--          <div class="text">未开票订单</div>-->
<!--        </div>-->
<!--        <div class="auditList">-->
<!--          <div class="item" v-for="(item,index) in 2">-->
<!--            <div class="block">-->
<!--              <el-avatar shape="square" :size="50" :src="squareUrl"></el-avatar>-->
<!--            </div>-->
<!--            <div class="name">姓名信息</div>-->
<!--            <div class="audit">审核></div>-->
<!--          </div>-->
<!--          <div class="more">查看更多</div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
<!--  </div>-->
</template>

<script>
export default {
  name: "userInfo",
  data(){
    return {
      squareUrl:'https://s4.ax1x.com/2021/12/14/ojgyqI.jpg'
    }
  },
  methods:{

  }
}
</script>

<style scoped lang="less">
  .userInfo{
    width: 300px;
    height: 100vh;
    //background: pink;
    float: right;
    overflow: hidden;
    padding:10px;
    box-sizing: border-box;
    .info{
      padding: 30px 20px;
      display: flex;
      align-items: center;
      background: white;
      &>div{
        flex: 1;
        text-align: center;
      }
    }
    .auditInfo{
      padding: 20px 0 20px 20px;
      //margin-left: 20px;
      .audit_header{
        border-bottom: 1px solid #999;
        padding-bottom: 15px;
        margin-bottom: 15px;
        .num{
          font-size: 24px;
          font-weight: bold;
          padding: 10px 0;
        }
      }
      .auditList{
        &::-webkit-scrollbar {
          width: 6px;
          /*height: 4px;*/
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
          background: rgba(0,0,0,0.2);
        }
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
          border-radius: 0;
          background: rgba(0,0,0,0.1);
        }

        .item{
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          &>div{
            flex: 1;
          }
        }
        .more{
          margin-top: 20px;
          text-align: center;
          align-items: center;
        }
      }
    }
  }
</style>
