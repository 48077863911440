<template>
  <div class="content">
    <el-drawer
        :show-close="false"
        :visible="true"
        :direction="'ttb'">
      <div @click="handleClose" class="el-icon-close iconXtext"></div>
      <div v-if="navitem==1" class="content_1">
        <div class="content_1_1">
          <div class="content_1_1_title">投诉建议</div>
          <div class="content_1_1_text">输入您的投诉建议内容，平台会联系您</div>
          <div class="content_1_1_title_inp"><el-input v-model="input" placeholder="请输入联系方式"></el-input></div>
          <el-input
              class="textarea"
              type="textarea"
              :rows="3"
              resize="none"
              placeholder="请输入您要咨询内容"
              v-model="textarea">
          </el-input>
          <div class="btn">提交</div>
        </div>
      </div>
      <div v-if="navitem==2" class="content_2">
        <div class="content_1_1">
          <div class="content_1_1_title">联系方式</div>
          <div class="content_1_1_text">欢迎来到无锡江南古运河旅游度假区！了解度假区更多消息</div>
        </div>
        <div class="content_list">
          <div style="display: flex;justify-content: space-between">
            <div class="item"><span>咨询电话:</span>0510-XXXXXXX</div>
            <div class="item"><span>传真:</span>0510-87471355</div>
          </div>
          <div style="display: flex;justify-content: space-between">
            <div class="item"><span>投诉电话:</span>0510-XXXXXXX</div>
            <div class="item"><span>邮箱:</span>214000</div>
          </div>
          <div style="display: flex;justify-content: space-between">
            <div class="item"><span>救助电话:</span>0510-XXXXXXX</div>
            <div class="item"><span>邮箱:</span>yangxian_trip@163.com</div>
          </div>

        </div>
      </div>
      <div v-if="navitem==3" class="sousuo">
        <p class="title">搜索</p>
        <p class="titles">输入关键字进行搜索内容</p>
        <div class="inp"><el-input v-model="val" placeholder="请输入内容"></el-input></div>
        <el-button class="inpBtn" style="background: rgba(0, 123, 138, 1);border: #007B8A 1px solid" type="success">搜索</el-button>
      </div>
    </el-drawer>

  </div>
</template>

<script>
export default {
name: "alertInfo",
  props:['closeAlert','type'],
  data(){
    return {
      input: '',
      textarea:'',
      navitem:null,
      val:'',
    }
  },
  mounted() {
  this.navitem=this.type
  },
  methods:{

    handleClose(){
      console.log(123)
      this.$emit('closeAlert',false)
    }
  }
}
</script>

<style scoped lang="less">
/deep/.el-drawer.btt, /deep/.el-drawer.ttb{
  height: 435px !important;
  background: rgba(7, 77, 92, 0.9);
  border-radius: 0 0 47px 47px;
}
.content_1{
  width: 800px;
  //height: 435px;
  margin: 0 auto;
}
.content_1_1{
  .content_1_1_title{
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 42px;
    text-align: center;
  }
  .content_1_1_text{
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.49);
    line-height: 39px;
    text-align: center;

  }
  .content_1_1_title_inp{
    //width: 320px;
    font-size: 18px;
  }
  .textarea{
    margin-top: 20px;
    font-size: 18px;
    //width: 600px;
  }
  .btn{
    width: 118px;
    height: 44px;
    border-radius: 22px;
    background: #FFFFFF;
    text-align: center;
    line-height: 44px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #337D8D;
    display: inline-block;
    float: right;
    margin-top: 20px;
  }
}
.iconXtext{
  font-size: 32px;
  color: #FFFFFF;
  float: right;
  margin-right: 150px;
}
.sousuo{
  margin-top: 100px;
  text-align: center;
  .title{
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 32px;
    text-align: center;
  }
  .titles{
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.49);
    line-height: 39px;
    margin-bottom: 10px;
  }
  .inp{
    //width: 400px;
    display: inline-block;
    .el-input{
      width: 668px!important;
      height: 56px;
      background: #FFFFFF;
      border-radius: 28px;
      /deep/.el-input__inner{
        width: 668px!important;
        height: 56px;
        background: #FFFFFF;
        border-radius: 28px;
        font-size: 18px;
        padding-left: 10px;

      }
    }
  }
  .inpBtn{
    width: 94px;
    height: 40px;
    background: #337D8D;
    border-radius: 28px;
    margin-left: -101px;
    position: relative;
    z-index: 9;
  }
}
.content_2{
  width: 860px;
  margin: auto;
  .content_1_1{
    margin-bottom: 25px;
    .content_1_1_title{
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
    }
    .content_1_1_text{
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.49);
      line-height: 39px;
    }
  }
  .content_list{
    //display: flex;
    //flex-wrap: wrap;
    .item{
      width: 320px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      //font-weight: 400;
      color: #FFFFFF;
      line-height: 50px;
      &:nth-child(2n){
        margin-left: 200px;
      }
    }
  }

}
</style>
